import React from 'react';
// import _ from 'lodash';

import Slider from "react-slick";


export default class CarouselHero extends React.Component {
  render() {
    const settings = {
      dots: false,
      fade: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            dots: true
          }
        },
      ]
    };
    return (
      <div className="carousel-v2">
      <Slider {...settings}>
        <div className="slide-1">
          <div className="slide-content bg-platform inner">
            <div className="slide-copy">
            <h2>A modern, intelligent and fast-to-implement platform</h2>
            </div>
          </div>
        </div>
        <div className="slide-2">
          <div className="slide-content bg-incenteeva inner">
            <div className="slide-copy">
            <h2>Manage your campaigns and incentive programs on a single platform</h2>
            </div>
          </div>
        </div>
        <div className="slide-3">
          <div className="slide-content bg-feedelo inner">
            <div className="slide-copy">
            <h2>Reward and loyalty quickly and with excellent returns</h2>
            </div>
          </div>
        </div>
        <div className="slide-4">
          <div className="slide-content bg-exteema inner">
            <div className="slide-copy">
            <h2>Take care of your employees and retain talent</h2>
            </div>
          </div>
        </div>
        <div className="slide-5">
          <div className="slide-content bg-conecteeva inner">
            <div className="slide-copy">
            <h2>We connect different audiences through intuitive interfaces</h2>
            </div>
          </div>
        </div>
      </Slider>
    </div>
    );
  }
}
